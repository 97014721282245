<template>
  <div class="products category-leaflet-slider">
    <Breadcrumb :items="breadcrumbs" />
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-header-1"
    />
    <CategoryTitle
      :category="category"
      :selectedCategory="selectedCategory"
      :count="count"
      :key="category.categoryId"
    />

    <v-container v-if="categoryId">
      <ProductListGrid
        :fluid="$vuetify.breakpoint.xsOnly"
        :parentCategoryId="category.categoryId"
        :categoryId="categoryId"
        :key="category.categoryId"
        :hideFilters="true"
        hideFilterString="Categorie,Main Term"
        @productsCount="updateCount"
        :pageSize="$ebsn.meta(category, 'template_model.PAGE_SIZE')"
        :virtualScrollViewport="$vuetify.breakpoint.xsOnly"
        :virtualPageSize="$vuetify.breakpoint.xsOnly ? 4 : 1"
      />
    </v-container>
    <template v-else>
      <v-container
        v-for="(subCategory, index) in category.children"
        :key="subCategory.categoryId"
      >
        <!-- index:{{ index }} sliderCounter: {{ sliderCounter }} -->
        <div class="product-slider-wrapper-container">
          <ProductSliderWrapper
            :id="index"
            :counter="sliderCounter"
            :title="subCategory.name"
            :category="category"
            :subCategory="subCategory"
            :showArrows="true"
            :showBullets="true"
            @updateSliderWrapperViewable="updateSliderWrapperViewable"
          />
        </div>
        <!-- <v-btn
          v-if="index > 4 && index % 3 == 0 && index > sliderCounter"
          v-intersect="onIntersect(index)"
          class="mt-4"
          block
          depressed
        >
        </v-btn> -->
      </v-container>
    </template>
    <category-block
      :target="category"
      position="position10"
      class="category-block category-block-footer-1"
    />
  </div>
</template>
<style lang="scss">
.category-leaflet-slider {
  .category-block-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1rem;
    text-align: center;
    justify-content: center;
    word-break: break-word;
  }
  .center-img {
    margin-right: auto;
    margin-left: auto;
  }
  .product-slider-wrapper-container {
    min-height: 356px;
  }
  .title-row h2 {
    font-size: 1.75rem;
    font-weight: 600 !important;
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import ProductListGrid from "@/components/product/ProductListGrid.vue";
import ProductSliderWrapper from "@/components/product/ProductSliderWrapper.vue";

import categoryMixins from "~/mixins/category";

import toNumber from "lodash/toNumber";

export default {
  name: "CategoryLeafletSlider",
  mixins: [categoryMixins],
  data() {
    return {
      categoryId: null,
      count: null,
      sliderCounter: 3
    };
  },
  components: {
    CategoryTitle,
    Breadcrumb,
    ProductSliderWrapper,
    ProductListGrid
  },
  computed: {
    breadcrumbs() {
      let breadCrumbs = [];

      breadCrumbs.push({
        to: { name: "Home" },
        text: "Home",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "Category",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      if (this.categoryId) {
        let subCategory = this.category.children.find(
          cat => cat.categoryId == this.categoryId
        );
        breadCrumbs.push({
          to: {
            name: "Category",
            params: {
              pathMatch: this.category.slug
            },
            query: { categoryId: subCategory.categoryId }
          },
          text: subCategory.name,
          exact: true
        });
      }
      return breadCrumbs;
    },
    selectedCategory() {
      if (this.categoryId) {
        return this.category.children.find(
          item => item.categoryId == this.categoryId
        );
      } else {
        return null;
      }
    }
  },
  methods: {
    updateSliderWrapperViewable(id) {
      this.sliderCounter = Math.max(id, this.sliderCounter);
    },
    updateCount(count) {
      this.count = count;
    }
  },
  mounted() {
    this.categoryId = toNumber(this.$route.query.categoryId);
  },
  watch: {
    async "$route.query.categoryId"() {
      this.categoryId = this.$route.query.categoryId;
    }
  }
};
</script>
